import React, { useState, useEffect, useRef, useMemo, useCallback} from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided } from '@hello-pangea/dnd';
import { Player, NFLPosition, MLBPosition, MLBPlayer, NFLPlayer} from '../data-components/Player';
import { isMLBPlayer, isNFLPlayer } from '../data-components/typeGuards';
import {calculatePositionRanks, resetOverallRanks} from '../data-components/startupData'
import { getTeamLogo } from '../utils/logoHelper';
import { exportMLBToCSV, exportNFLToCSV, importMLBFromCSV, importNFLFromCSV } from '../utils/csv-tools';
import Tooltip from './Tooltip';
import './PlayerList.css';
import star_selected from '../icons/star-selected.png'
import star_unselected from '../icons/star-unselected.png'
import trash from '../icons/trash.png'
import {Sport} from '../App'
import MessageBox, {MessageInfo, MessageType} from './MessageBox';


interface PlayerListProps {
  players: Player[];
  sport: Sport;
  onDragEnd: (players: Player[], needToCallAPIs: boolean, importing?: boolean) => void;
  onReset: (draftOccured: boolean) => void;
  onMessageUpdate: (message: MessageInfo | null) => void;
}

const PlayerList: React.FC<PlayerListProps> = ({ players, onDragEnd, onReset, sport, onMessageUpdate}) => {
  const [filteredPlayers, setFilteredPlayers] = useState<Player[]>(players);
  const [selectedFilter, setSelectedFilter] = useState<string>('ALL');
  const [favoriteFilter, setFavoriteFilter] = useState<boolean>(false);
  const [rookieFilter, setRookieFilter] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState<MessageInfo | null>(null);
  const mainFields = ["adp", "name", "team", "bye", "position", "positionRank", "isRookie", "favorite", "overallRank", "drafted", "additionalProps", "otherPositions", "PositionRankText"];

  useEffect(() => {

    switch (sport)
    {
      case Sport.NFL:
        applyFiltersNFL();
        break;
      case Sport.MLB:
        applyFiltersMLB();
        break;
    }
  }, [players, selectedFilter, favoriteFilter, rookieFilter]);

  useEffect(() => {

    setSelectedFilter('ALL');
  }
, [sport]);

useEffect(() => {
  if (message)
  {
    onMessageUpdate(message)
  }

}
, [message, onMessageUpdate]);

  const applyFiltersNFL = () => {
    let filtered = players;

    if (selectedFilter !== 'ALL') {
      filtered = filtered.filter(player => player.position === selectedFilter || (selectedFilter === 'FLEX' && isNFLPlayer(player) && player.isFlexPosition()));
    }

    if (favoriteFilter) {
      filtered = filtered.filter(player => isNFLPlayer(player) && player.favorite);
    }

    if (rookieFilter) {
      filtered = filtered.filter(player => isNFLPlayer(player) && player.isRookie);
    }

    filtered = filtered.sort((a, b) => a.overallRank - b.overallRank);

    setFilteredPlayers(filtered);
  };

  const applyFiltersMLB = () => {
    let filtered = players;
    

    if (selectedFilter !== 'ALL') {
      filtered = filtered.filter(player => player.position === selectedFilter || isMLBPlayer(player) && player.otherPositions?.includes(selectedFilter as MLBPosition) ||
        (selectedFilter === 'UTIL' && isMLBPlayer(player) && player.isUTIL()) || (selectedFilter === 'P' && isMLBPlayer(player) && player.isPitcher()));
    }


    if (favoriteFilter) {
      filtered = filtered.filter(player => isMLBPlayer(player) && player.favorite);
    }

    if (rookieFilter) {
      filtered = filtered.filter(player => isMLBPlayer(player) && player.isRookie);
    }

    filtered = filtered.sort((a, b) => a.overallRank - b.overallRank);

    setFilteredPlayers(filtered);
  };

  const handlePositionFilterChange = (position: string) => {
    setSelectedFilter(position);
  };

  const toggleFavoriteFilter = () => {
    setFavoriteFilter(prev => !prev);
  };

  const toggleRookieFilter = () => {
    setRookieFilter(prev => !prev);
  };


  const updatePositionRanks = (updatedPlayers: Player[]) => {

    let positions = [];
    switch (sport) {
      case Sport.NFL:
        positions = [NFLPosition.QB, NFLPosition.RB, NFLPosition.WR, NFLPosition.TE, NFLPosition.K, NFLPosition.DST];
        break;
      case Sport.MLB:
        positions = [MLBPosition.SP, MLBPosition.RP, MLBPosition.C, MLBPosition.B1, MLBPosition.B2, MLBPosition.B3, MLBPosition.SS, MLBPosition.OF, MLBPosition.DH]
        break;  
      default:
        return [];
    }

    const playersCopy = [...updatedPlayers];

    positions.forEach(position => {
      let rank = 1;
      playersCopy
        .filter(player => player.position === position)
        .forEach(player => {
          if (isMLBPlayer(player) && sport === Sport.MLB || isNFLPlayer(player) && sport === Sport.NFL) {
            player.positionRank = rank++;
          }
        });
    });

    return playersCopy;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const isFiltered = selectedFilter !== "ALL";

    const currentFilteredPlayers = Array.from(filteredPlayers);
    const displacedPlayer = currentFilteredPlayers[result.destination.index];

    //move player in filtered list
    const [movedPlayer] = currentFilteredPlayers.splice(result.source.index, 1);
    currentFilteredPlayers.splice(result.destination.index, 0, movedPlayer);

    const globalPlayers = Array.from(players);

    if (isFiltered) {
      //flags
      const playerMovedUp = result.destination.index < result.source.index; //moved up page

      const beatenOverall = displacedPlayer.overallRank;
      const previousMovedPlayerOverall = movedPlayer.overallRank;
      currentFilteredPlayers[result.destination.index].overallRank = beatenOverall;

      //this will contain references to update overall values later based on index
      let indexesToSwap: { index: number, overall: number }[] = [];

      currentFilteredPlayers.map((pl, index) => {

        if (result.destination == null || result.source == null) {
          console.error("bad destination for drop");
          return;
        }

        if (playerMovedUp) {

          if (result.destination.index <= index && result.source.index >= index) {
            indexesToSwap.push({ index: index, overall: pl.overallRank });
          }

          //new tail
          if (index === result.source.index) {
            currentFilteredPlayers[result.source.index].overallRank = previousMovedPlayerOverall;
          }
        }
        else {
          if (result.source.index <= index && result.destination.index >= index) {
            indexesToSwap.push({ index: index, overall: pl.overallRank });
          }

          //new head
          if (index === result.source.index) {
            currentFilteredPlayers[result.source.index].overallRank = previousMovedPlayerOverall;
          }
        }


      })

      //handle history of shifted players based on up or down movement
      if (playerMovedUp) {
        for (var i = 0; i < indexesToSwap.length - 1; i++) {
          var filteredIndexValue = indexesToSwap[i].index;
          var newOverallRank = indexesToSwap[i + 1].overall;

          currentFilteredPlayers[filteredIndexValue].overallRank = newOverallRank;
        }
      }
      else {
        for (var k = indexesToSwap.length - 1; k > 0; k--) {
          var filteredIndexValue2 = indexesToSwap[k].index;
          var newOverallRank2 = indexesToSwap[k - 1].overall;

          currentFilteredPlayers[filteredIndexValue2].overallRank = newOverallRank2;
        }
      }

      const movedPlayerGlobalIndex = globalPlayers.findIndex(player => player.name === movedPlayer.name);

      globalPlayers.splice(movedPlayerGlobalIndex, 1);

      //calculate the correct new index in the global list
      const newGlobalIndex = beatenOverall - 1;
      globalPlayers.splice(newGlobalIndex, 0, movedPlayer);
    }

    //not filtered.  just adjust ranking globally
    if (!isFiltered) {
      //update the global order based on the filtered list movement
      const movedPlayerGlobalIndex = globalPlayers.findIndex(player => player.name === movedPlayer.name);

      globalPlayers.splice(movedPlayerGlobalIndex, 1);

      //calc the correct new index in the global list
      const newGlobalIndex = result.destination.index;
      globalPlayers.splice(newGlobalIndex, 0, movedPlayer);

      //update overall rankings
      globalPlayers.forEach((player, index) => {
        player.overallRank = index + 1;
      });
    }


    const updatedPlayers = updatePositionRanks(globalPlayers);

    switch (sport) {
      case Sport.NFL:
        applyFiltersNFL();
        break;
      case Sport.MLB:
        applyFiltersMLB();
        break;  
      default:
        return null;
    }
    onDragEnd(updatedPlayers, false);
  };

  const toggleFavorite = (player: Player) => {
    player.favorite = !player.favorite;
    setFilteredPlayers([...filteredPlayers]);
    onDragEnd([...players], false);
  };

  const handleReset = () => {
    setFavoriteFilter(false);
    setRookieFilter(false);
    setSelectedFilter("ALL");
    const draftOccured = players.some(d => d.drafted);

    if (draftOccured)
    {
      // setDraftedPlayers([]);
      players.forEach(p => {
        p.drafted = false;
      })
      onDragEnd([...players], false);
      return;
    }

    onReset(draftOccured);

  };

  const handleExportClick = () => {
    try
    {
      switch (sport) {
        case Sport.NFL:
          exportNFLToCSV(filteredPlayers, selectedFilter, favoriteFilter, rookieFilter);
          break;
        case Sport.MLB:
          exportMLBToCSV(filteredPlayers, selectedFilter, favoriteFilter, rookieFilter);
          break;  
        default:
          return null;
      }
    }
    catch (e: any)
    {
      console.error(e);
      setMessage(MessageBox.createMessageInfo((e as Error).message, MessageType.Error));
    }

  };

  const handleImportClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const text = e.target?.result;
            if (text) {
              let importedPlayers = [];
              switch (sport) {
                case Sport.NFL: 
                  importedPlayers = importNFLFromCSV(text.toString());
                  importedPlayers = calculatePositionRanks(importedPlayers);
                  importedPlayers = resetOverallRanks(importedPlayers as NFLPlayer[]);
                  break;
                case Sport.MLB:
                  importedPlayers = importMLBFromCSV(text.toString());
                  importedPlayers = calculatePositionRanks(importedPlayers);
                  importedPlayers = resetOverallRanks(importedPlayers as MLBPlayer[]);
                  break;
                default:
                  return null;
              }
  
              onDragEnd(importedPlayers, true, true);
            }
          } catch (error) {
            console.error(error);

            let friendlyError = (error as Error).message;

            setMessage(MessageBox.createMessageInfo(friendlyError, MessageType.Error))
            return;
          }
          finally {
            event.target.value = ''; //reset so they can click again
          }

        };
        reader.readAsText(file);
      }
      else {
        setMessage(MessageBox.createMessageInfo("No file supplied", MessageType.Info))
      }
    } 
    catch (error) {
      console.error(error);
      let friendlyError = (error as Error).message;
      setMessage(MessageBox.createMessageInfo(friendlyError, MessageType.Error))
    }

  };

  const handleDrafted = (draftedPlayer: Player) => {

    draftedPlayer.drafted = true;
    setFilteredPlayers([...filteredPlayers]);
    onDragEnd([...players], false);

  };

    const NFLFilterButtons: React.FC = () => {
      return (
        <>
          <button className="position-ALL" onClick={() => { handlePositionFilterChange('ALL'); setFavoriteFilter(false); setRookieFilter(false); }}>ALL</button>
          <button className="position QB" onClick={() => handlePositionFilterChange(NFLPosition.QB)}>QB</button>
          <button className="position RB" onClick={() => handlePositionFilterChange(NFLPosition.RB)}>RB</button>
          <button className="position WR" onClick={() => handlePositionFilterChange(NFLPosition.WR)}>WR</button>
          <button className="position TE" onClick={() => handlePositionFilterChange(NFLPosition.TE)}>TE</button>
          <button className="position K" onClick={() => handlePositionFilterChange(NFLPosition.K)}>K</button>
          <button className="position DST" onClick={() => handlePositionFilterChange(NFLPosition.DST)}>D/ST</button>
          <button className="position FLEX" onClick={() => handlePositionFilterChange('FLEX')}>FLEX</button>
          <button className={`filter FAV ${favoriteFilter ? "ON" : "OFF"}`} onClick={() => toggleFavoriteFilter()}>FAV</button>
          <button className={`filter ROOKIE ${rookieFilter ? "ON" : "OFF"}`} onClick={() => toggleRookieFilter()}>R</button>
          <button className={`reset-button ${players.some(f => f.drafted) ? "" : "RESET"}`} onClick={handleReset}>{players.some(f => f.drafted) ? "UNDRAFT" : "RESET"}</button>
        </>
      )
    };

  const NFLPlayerTable: React.FC<{ provided: DroppableProvided }> = ({ provided }) => {

    return (
      <div {...provided.droppableProps} ref={provided.innerRef} className="player-list">
        <div className="player-list-header">
          <span></span>
          <Tooltip text="Rank" tooltipText="Overall PPR rank (FantasyPros)" direction="right"/>
          <span className="playerNameOnly">Name</span>
          <Tooltip text="ADP" tooltipText="Average PPR Draft Position (FantasyPros)" direction='left' />
        </div>
        {filteredPlayers.map((player, index) => (
          <Draggable key={player.getUniqueKey()} draggableId={player.getUniqueKey()} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                // className='player-card'
                className={`player-card ${player.drafted ? 'drafted' : ''}`}
                // style={{ display: player.drafted ? 'none' : 'grid' }}
              >
                <button className="delete-button" onClick={() => handleDrafted(player)}>
                  <img src={trash} alt="Delete" className="delete-icon"></img>
                </button>
                <span className="overall-rank">{player.overallRank}</span>
                <div className="player-info">
                  <span className="player-name">
                    {player.name}
                    {player.isRookie && <span className="rookie-flag"> R</span>}
                  </span>
                  <button className={`favorite-button ${player.favorite ? 'selected' : ''}`} onClick={() => toggleFavorite(player)}>
                    <img src={player.favorite ? star_selected : star_unselected} alt="Favorite" />
                  </button>
                  {isNFLPlayer(player) ? (
                    <div className="player-details">
                      <div className="team-position-container">
                        <img src={getTeamLogo(player.team, sport)} alt={player.team} className="team-logo" />
                        <span className="player-team">{player.team}</span>
                        <span className={`player-position ${player.position}`}>{player.getPositionRankString()}</span>
                        {player.bye !== undefined && <span className="player-bye">Bye: {player.bye}</span>}
                      </div>
                      <div className="subPositions-container"></div>
                    </div>
                  ) : (
                    undefined
                  )}
                </div>
                {isNFLPlayer(player) && (
                  <>
                    {player.adp !== undefined && <span className="player-adp">{player.adp !== null ? player.adp.toFixed(1) : "N/A"}</span>}
                    {
                      <div className="player-additional-info">
                      {Object.entries(player)
                        .filter(([key]) => !mainFields.includes(key))
                        .map(([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value !== null && value !== undefined ? (value instanceof Array && !(value instanceof String) ? (value as Array<any>).join(",") : value.toString()) : 'N/A'}
                          </div>
                        ))}
                    </div>
                    }
                    {/* {player.projectedTotalPoints !== undefined && <span className="player-projected">{player.projectedTotalPoints !== null ? player.projectedTotalPoints : "N/A"}</span>} */}
                  </>
                )}
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>
    );
  };

  //NFL Components
  const MLBFilterButtons: React.FC = () => {
    return (
      <>
        <button className="position-ALL" onClick={() => { handlePositionFilterChange('ALL'); setFavoriteFilter(false); setRookieFilter(false); }}>ALL</button>
        <button className="position SP" onClick={() => handlePositionFilterChange(MLBPosition.SP)}>SP</button>
        <button className="position RP" onClick={() => handlePositionFilterChange(MLBPosition.RP)}>RP</button>
        <button className="position C" onClick={() => handlePositionFilterChange(MLBPosition.C)}>C</button>
        <button className="position B1" onClick={() => handlePositionFilterChange(MLBPosition.B1)}>1B</button>
        <button className="position B2" onClick={() => handlePositionFilterChange(MLBPosition.B2)}>2B</button>
        <button className="position SS" onClick={() => handlePositionFilterChange(MLBPosition.SS)}>SS</button>
        <button className="position B3" onClick={() => handlePositionFilterChange(MLBPosition.B3)}>3B</button>
        <button className="position OF" onClick={() => handlePositionFilterChange(MLBPosition.OF)}>OF</button>
        <button className="position DH" onClick={() => handlePositionFilterChange(MLBPosition.DH)}>DH</button>
        <button className="position PITCHERS" onClick={() => handlePositionFilterChange('P')}>P</button>
        <button className="position BATTERS" onClick={() => handlePositionFilterChange('UTIL')}>UTIL</button>
        <button className={`filter FAV ${favoriteFilter ? "ON" : "OFF"}`} onClick={() => toggleFavoriteFilter()}>FAV</button>
        <button className={`filter ROOKIE ${rookieFilter ? "ON" : "OFF"}`} onClick={() => toggleRookieFilter()}>R</button>
        <button className={`reset-button ${players.some(f => f.drafted) ? "" : "RESET"}`} onClick={handleReset}>{players.some(f => f.drafted) ? "UNDRAFT" : "RESET"}</button>
      </>
    )
  };

  const MLBPlayerTable: React.FC<{ provided: DroppableProvided }> = ({ provided }) => {
    return (
      <div {...provided.droppableProps} ref={provided.innerRef} className="player-list">
        <div className="player-list-header">
          <span></span>
          <Tooltip text="Rank" tooltipText="Overall rank (FantasyPros)" direction='right' />
          <span className="playerNameOnly">Name</span>
          <Tooltip text="ADP" tooltipText="Average Draft Position (FantasyPros)" direction='left'/>
        </div>
        {filteredPlayers.map((player, index) => (
          <Draggable key={player.getUniqueKey()} draggableId={player.getUniqueKey()} index={index}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                //className='player-card'
                className={`player-card ${player.drafted ? 'drafted' : ''}`}
              >
                <button className="delete-button" onClick={() => handleDrafted(player)}>
                  <img src={trash} alt="Delete" className="delete-icon"></img>
                </button>
                <span className="overall-rank">{player.overallRank}</span>
                <div className="player-info">
                  <span className="player-name">
                    {player.name}
                    {player.isRookie && <span className="rookie-flag"> R</span>}
                  </span>
                  <button className={`favorite-button ${player.favorite ? 'selected' : ''}`} onClick={() => toggleFavorite(player)}>
                    <img src={player.favorite ? star_selected : star_unselected} alt="Favorite" />
                  </button>
                  {isMLBPlayer(player) ? (
                    <div className="player-details">
                      <div className="team-position-container">
                        <img src={getTeamLogo(player.team, sport)} alt={player.team} className="team-logo" />
                        <span className="player-team">{player.team}</span>
                        <span className={`player-position ${player.position}`}>{player.getPositionRankString()}</span>
                      </div>
                      <div className="subPositions-container">
                        {player.otherPositions?.map((o, index) => (
                          player.position !== o ? (
                            <span key={index} className={`player-position ${o} subPositions`}>{MLBPlayer.getPrettyPosition(o)}</span>
                          ) : null
                        ))}
                      </div>
                  </div>
                  ) : (
                    undefined
                  )}
                </div>
                {isMLBPlayer(player) && (
                  <>
                    {player.adp !== undefined && <span className="player-adp">{player.adp !== null ? player.adp.toFixed(1) : "N/A"}</span>}
                    <div className="player-additional-info">
                      {Object.entries(player)
                        .filter(([key]) => !mainFields.includes(key))
                        .map(([key, value]) => (
                          <div key={key}>
                            <strong>{key}:</strong> {value !== null && value !== undefined ? (value instanceof Array ? (value as Array<any>).join(",") : value) : 'N/A'}
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>
    );
  };
  
  const renderPlayerList = (provided: DroppableProvided) => {
    switch (sport) {
      case Sport.NFL:
        return <NFLPlayerTable provided={provided} />;
      case Sport.MLB:
        return <MLBPlayerTable provided={provided} />;
      default:
        return null;
    }
  };

  const renderButtons = () => {
    switch (sport) {
      case Sport.NFL:
        return <NFLFilterButtons />;
      case Sport.MLB:
        return <MLBFilterButtons />;
      case Sport.MISC:
        return undefined;
      default:
        return undefined;
    }
  };

  return (
    <>
      <div className="export-import-buttons">
        <button className="import-button" onClick={handleImportClick}>Import from CSV</button>
        <button className="export-button" onClick={handleExportClick}>Export to CSV</button>
        <input type="file" accept=".csv" ref={fileInputRef}style={{ display: 'none' }} onChange={handleFileChange}/>
      </div>
      <div className="filter-buttons">
        {renderButtons()}
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
      
        <Droppable droppableId="players">
          
          {(provided) => (
            renderPlayerList(provided)
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};


export default PlayerList;
