import React, { useState } from 'react';
import Modal from 'react-modal';
import './Footer.css';
import top_arrow_button_icon from '../icons/top-button-arrow.png';
import Attributions from './Attributions';

const Footer: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showAttributions, setShowAttributions] = useState(false);
  const [hoveringOverAttributions, setHoveringOverAttributions] = useState(false);

  // const openModal = () => setModalIsOpen(true);
  // const closeModal = () => setModalIsOpen(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <footer className="App-footer">
        <div className="footer-content">
          <button onClick={toggleModal} className="info-button">About</button>

          <div className="footer-text">
            <h1>&copy; 2024 Draft Deck</h1>
            <h2>Drag and drop rankings tool for Fantasy Drafts - Jeffrey Antetomaso</h2>
          </div>

          <button className="backtotop-button" onClick={backToTop}>
            <img src={top_arrow_button_icon} alt="Back to Top" />
          </button>
        </div>
        <div className="Modal" onClick={() => {if (!hoveringOverAttributions) {toggleModal()}}}>
          <Modal isOpen={modalIsOpen} onRequestClose={toggleModal} contentLabel="about" appElement={document.getElementById('root') || undefined}>
          <h1>Draft Deck</h1>
          {/* <div className='intro'>I built this tool because I didn't find Excel very friendly for organizing rankings.  All the "experts" seem to just regurgitate the same rankings, data points, and opinions and I think it takes the fun out of fantasy.  Go out and draft that </div> */}
          <h2>Key Features:</h2>
          <ul>
              <li><strong>Drag and Drop Interface</strong>: Easily rearrange player rankings by dragging and dropping.</li>
              <li><strong>Drafting Players</strong>: Use the red dash button to draft or undraft players, simulating real draft selections.</li>
              <li><strong>Advanced Sorting and Filtering</strong>: Filter and rank players by position, with changes reflected across all views.</li>
              <li><strong>Import/Export to CSV</strong>: Manage and transfer your rankings with CSV import/export functionality.</li>
          </ul>

          <p>Draft Deck is a tool for preparing and managing your fantasy NFL/MLB drafts. Whether you're gearing up for the draft or making decisions in real-time, Draft Deck gives you the flexibility to organize and rank players according to your own strategy, rather than relying on pre-defined rankings found online.</p>

          <h3>Detailed Features:</h3>

          <p><strong>Drag and Drop Interface</strong>: Effortlessly rearrange players in your rankings by dragging and dropping them into your preferred order. This intuitive feature allows for a more personalized and straightforward approach to drafting.</p>
          
          <p><strong>Drafting Players</strong>: The red dash button on the left of each player acts as a "draft" button, removing the player from the list and simulating their selection in the draft. The "Reset" button changes to "Undraft" once players are drafted. Clicking "Undraft" will return the drafted players to the list, while clicking "Reset" will pull updated rankings from FantasyPros.com as a reference.</p>
          
          <p><strong>Sorting and Filtering</strong>: Draft Deck offers powerful filtering options by position, with intelligent handling for position-specific rankings. For instance, moving a player from WR5 to WR2 will swap their rankings within the wide receiver section. When viewing the "All" or "Flex" sections, these changes will adjust the overall rankings accordingly. This system allows you to rank each position group individually, making it easier to create your overall rankings.</p>
          
          <p><strong>Import/Export to CSV</strong>: Seamlessly import and export your rankings to CSV files, making it easy to manage your data across different platforms. Specific fields are required for both NFL and MLB (e.g., name, team, overall rank, position, ADP), while additional fields can be added and will be included in a notes section for each player.</p>
          
          <h2>Happy drafting!</h2>
          <div className='tinyText'>{"~ keaton mitchell truther ~"}</div>

          <div className='closeButton-container'>
            <button className='closeButton' onClick={toggleModal}>Close</button>
          </div>
          <div className='attributions-Container' onMouseEnter={() => setHoveringOverAttributions(true)} onMouseLeave={() => setHoveringOverAttributions(false)}>
            <div className="footer-attributions" onClick={() => setShowAttributions(!showAttributions)}>
              {showAttributions ? <Attributions /> : "Tap for attributions"}
            </div>
          </div>



          </Modal>
        </div>

      </footer>
    </>
  );
};

export default Footer;
