import { MLBPlayer, NFLPlayer, Player } from "./Player";
import {fetchMLBData, fetchNFLData} from "../apiHelpers/apiData"

export interface DefaultAPIData {nflPlayers: NFLPlayer[], mlbPlayers: MLBPlayer[]}

export function calculatePositionRanks(players: (MLBPlayer[] | NFLPlayer[])): Player[] {
  // Sort players by their overall rank
  players.sort((a, b) => a.overallRank - b.overallRank);

  // Initialize a dictionary to track the rank for each position
  const positionRanks: { [position: string]: number } = {};

  // Iterate over the players and assign position ranks
  for (const player of players) {
    if (!positionRanks[player.position]) {
      positionRanks[player.position] = 1;
    } else {
      positionRanks[player.position]++;
    }
    player.positionRank = positionRanks[player.position];
  }

  return players;
}

export function resetOverallRanks(players: (MLBPlayer[] | NFLPlayer[])): Player[] {
  // Sort players by their overall rank
  players.sort((a, b) => a.overallRank - b.overallRank);

  players.forEach((player, index) => {
    player.overallRank = index + 1;
  })

  // Adjust the ranks so that the lowest becomes 1, and the rest follow sequentially
  return players;
}

let defaultNFLPlayerList: NFLPlayer[] = [
  //hardcode backup players if necessary
];


let defaultMLBPlayerList: MLBPlayer[] = [
  //hardcode backup players if necessary
];


export async function initializeDefaultData() {
  try {
    // const [nflData, mlbData] = await Promise.all([fetchNFLData(), fetchMLBData()]);

    const [nflData] = await Promise.all([fetchNFLData()]);
    const [mlbData] = await Promise.all([fetchMLBData()]);

    defaultNFLPlayerList = nflData as NFLPlayer[];
    defaultMLBPlayerList = mlbData as MLBPlayer[];
    
    const defaults: DefaultAPIData = {nflPlayers: defaultNFLPlayerList, mlbPlayers: defaultMLBPlayerList}
    return defaults
  } catch (error) {
     throw error;
  }
}