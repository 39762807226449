export abstract class Player {
  constructor(
    public name: string,
    public team: string,
    public position: MLBPosition | NFLPosition | string ,
    public overallRank: number,
    public isRookie: boolean = false,
    public favorite: boolean = false,
    public drafted: boolean = false,
    public additionalProps?: { [key: string]: any }
  ) {
    if (additionalProps) {
      Object.assign(this, additionalProps);
    }
  }

  getUniqueKey(): string {
    return this.name + "|" + this.team + "|" + this.position
  }
}

export enum NFLPosition {
  QB = "QB",
  RB = "RB",
  WR = "WR",
  TE = "TE",
  K = "K",
  DST = "DST"
}

export const AFCTeams = ["BAL", "BUF", "CIN", "CLE", "DEN", "HOU", "IND", "JAC", "KC", "LAC", "LV", "MIA", "NE", "NYJ", "PIT", "TEN"];
export const NFCTeams = ["ARI", "ATL", "CAR", "CHI", "DAL", "DET", "GB", "LAR", "MIN", "NO", "NYG", "PHI", "SEA", "SF", "TB", "WAS"];
export const NFLTeams = AFCTeams.concat(NFCTeams).concat(["FA"]);

export class NFLPlayer extends Player {
  constructor(
    name: string,
    team: string,
    position: NFLPosition,
    overallRank: number,
    public adp?: number | null,
    // public projectedTotalPoints?: number  | null,
    public positionRank?: number  | null,
    public bye?: number  | null,
    isRookie: boolean = false,
    favorite: boolean = false,
    drafted: boolean = false,
    additionalProps?: { [key: string]: any } // To handle additional properties
  ) {
    super(name, team, position, overallRank, isRookie, favorite, drafted, additionalProps);
  }

  getPositionRankString(): string {
    return this.positionRank !== undefined ? `${this.position}-${this.positionRank}` : '';
  }

  isFlexPosition(): boolean {
    const flexPositions = ["RB", "WR", "TE"];
    return flexPositions.includes(this.position);
  }

  isSuperFlexPosition(): boolean {
    const superFlexPositions = ["QB", "RB", "WR", "TE"];
    return superFlexPositions.includes(this.position);
  }

  static fromJSON(json: any): NFLPlayer {
    const { name, team, position, overallRank, adp, projectedTotalPoints, positionRank, bye, isRookie, favorite, drafted, ...additionalProps } = json;
    return new NFLPlayer(
      name,
      team,
      position,
      overallRank,
      adp,
      // json.projectedTotalPoints,
      positionRank,
      bye,
      isRookie,
      favorite, drafted, additionalProps
    );
  }

  static deepCopy(players: NFLPlayer[]): NFLPlayer[] {
    
    return players.map(player => {
      const { name, team, position, overallRank, adp, 
        //projectedTotalPoints, 
        positionRank, bye, isRookie, favorite, drafted, ...additionalProps } = player;
      return new NFLPlayer(
        name,
        team,
        position as NFLPosition,
        overallRank,
        adp,
        //projectedTotalPoints,
        positionRank,
        bye,
        isRookie,
        favorite,
        drafted,
        additionalProps
      );
    });

  }
}

export type Throws = "L" | "R" | undefined;
export type Bats = "L" | "R" | "S" | undefined;
export type League = "AL" | "NL" | undefined;

export const throwsOptions = ["L", "R"] as const;
export const batsOptions = ["L", "R", "S"] as const;
export const leagueOptions = ["AL", "NL"] as const;


export interface PositionDataMLB {
  mainPosition: MLBPosition,
  otherPositions: MLBPosition[]
}


export enum MLBPosition {
  SP = "SP",   
  RP = "RP",   
  C = "C",   
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  SS = "SS", 
  OF = "OF", 
  DH = "DH"  
}

const hitters = ["C", "DH", "B1", "B2", "B3", "SS", "OF"];
const pitchers = ["SP", "RP"];

export const ALTeams = ["BAL", "BOS", "CLE", "CWS", "DET", "HOU", "KC", "LAA", "MIN", "NYY", "OAK", "SEA", "TB", "TEX", "TOR"];
export const NLTeams = ["ATL", "AZ", "CHC", "CIN", "COL", "LAD", "MIA", "MIL", "NYM", "PHI", "PIT", "SD", "SF", "STL", "WAS"];
export const MLBTeams = ALTeams.concat(NLTeams).concat(["FA"]);;

export class MLBPlayer extends Player {
  constructor(
    name: string,
    team: string,
    position: MLBPosition,
    overallRank: number,
    public adp?: number | null,
    public positionRank?: number  | null,
    isRookie: boolean = false,
    favorite: boolean = false,
    drafted: boolean = false,
    public otherPositions?: MLBPosition[],
    additionalProps?: { [key: string]: any } // To handle additional properties
  ) {
    super(name, team, position, overallRank, isRookie, favorite, drafted, additionalProps );
  }

  getPositionRankString(): string {
    return this.positionRank !== undefined ? `${MLBPlayer.getPrettyPosition(this.position)}-${this.positionRank}` : '';
  }

  static getPrettyPosition(position: string): string {
    let prettyPosition: string = position;
    const positionsToFlip = ["B1", "B2", "B3"];
    if (positionsToFlip.includes(position))
    {
      switch (position)
      {
        case "B1":
          prettyPosition = "1B"
          break;
        case "B2":
          prettyPosition = "2B"
          break;
        case "B3":
          prettyPosition = "3B"
          break;
      }
    }

    return prettyPosition;
  }

  isUTIL(): boolean {
    const playersPositions = [this.position]
    return hitters.includes(this.position);
  }

  isPitcher(): boolean {
    return pitchers.includes(this.position);
  }

  getLeague(): League {
    return ALTeams.includes(this.team) ? "AL" : "NL";
  }

  static fromJSON(json: any): MLBPlayer {
    const { name, team, position, overallRank, adp, projectedTotalPoints, positionRank, bye, isRookie, favorite, drafted, otherPositions, ...additionalProps } = json;
 
    return new MLBPlayer(
      name,
      team,
      position,
      overallRank,
      adp,
      positionRank,
      isRookie,
      favorite, drafted,
      otherPositions,
      additionalProps
    );
  }

  static deepCopy(players: MLBPlayer[]): MLBPlayer[] {
    
    return players.map(player => {
      const { name, team, position, overallRank, adp, 
        positionRank, isRookie, favorite, drafted, otherPositions, ...additionalProps } = player;
      return new MLBPlayer(
        name,
        team,
        position as MLBPosition,
        overallRank,
        adp,
        positionRank,
        isRookie,
        favorite,
        drafted,
        otherPositions,
        additionalProps
      );
    });
  }
}
